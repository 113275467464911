(function() {
    'use strict';

    angular
        .module('elogbooks.admin.sites')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails)
        .config(registerRoutesAssociates)
        .config(registerRoutesApprovers)
        .config(registerRoutesContacts)
        .config(registerRoutesLocations)
        .config(registerRoutesPartnerships)
        .config(registerRoutesWebhooks)
        .config(registerRoutesPriorities)
        .config(registerRoutesSpillageType)
        .config(registerRoutesWasteTreatmentTypes)
        .config(registerRoutesNotes)
        .config(registerRoutesThresholds)
        .config(registerRoutesKPISurveys)
        .config(registerBillingSettings)
        .config(registerBillingSettingsAdd)
        .config(registerBillingSettingsEdit);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.sites', {
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                },
                resolve: {
                    meridianConfigurationCollectionResponse: function (rootResourceResponse, apiClient) {
                        if (rootResourceResponse.getLink('meridian-configuration')) {
                            return apiClient.get(rootResourceResponse.getLink('meridian-configuration'), { type: 'systemLinks' }).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    }
                },
                data: {
                    permissions: ['user_permission_view_sites', 'user_permission_client_admin_manage_sites']
                }
            });
    }

    function registerRoutesList($stateProvider) {
        var params = {
            page: '1',
            limit: '30',
            order: 'id',
            id: null,
            siteName: null,
            reference: null,
            address: null,
            activeAt: null,
            inactiveAt: null,
            status: null,
            locationActive: 'yes',
            childLocationActive: 'yes',
            createdAtStart: null,
            createdAtEnd: null,
            externalReference: null,
            associateType: null,
            associate: null,
            sectors: {array: true},
            siteRegions: {array: true},
            siteGroups: {array: true},
            siteGroup: null,
            billingTypeId: null,
            approver: null,
        };
        $stateProvider
            .state('dashboard.admin.sites.list', {
                url: '/sites?' + Object.keys(params).join('&'),
                parent: 'dashboard.admin.sites',
                data: {
                    permissions: ['user_permission_view_sites', 'user_permission_client_admin_manage_sites']
                },
                params: params,
                views: {
                    '@dashboard.admin.sites': {
                        templateUrl: '/modules/admin/sites/site-list.html',
                        controller: 'SitesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    siteCollectionResponse: function($stateParams, apiClient, rootResourceResponse, globalFilterParamsService) {
                        var params = angular.extend({}, $stateParams,
                            {
                                page: $stateParams.page,
                                limit: $stateParams.limit,
                                siteName: $stateParams.siteName,
                                reference: $stateParams.reference,
                                status: $stateParams.status,
                                'siteRegions[]': $stateParams.siteRegions,
                                id: $stateParams.id,
                                activeAt: $stateParams.activeAt,
                                inactiveAt: $stateParams.inactiveAt,
                                address: $stateParams.address,
                                externalReference: $stateParams.externalReference,
                                associate: $stateParams.associate,
                                associateType: $stateParams.associateType,
                                'siteGroups[]': $stateParams.siteGroups,
                                siteGroup: $stateParams.siteGroup ? JSON.parse($stateParams.siteGroup).v : null,
                                'siteRegion[]': $stateParams.siteRegion,
                                'sectors[]': $stateParams.sectors,
                                skipNavigationConfirmation: false,
                                createdAtStart: $stateParams.createdAtStart = $stateParams.createdAtStart ? new Date($stateParams.createdAtStart).toISOString() : null,
                                createdAtEnd: $stateParams.createdAtEnd = $stateParams.createdAtEnd ? new Date($stateParams.createdAtEnd).toISOString() : null,
                                approver: JSON.parse($stateParams.approver)?.v || null,
                            }
                        );

                        params = angular.extend({}, params, globalFilterParamsService.getLocalStorageFilters());

                        return apiClient.get(rootResourceResponse.getLink('sites'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteGroupCollectionResponse: function($stateParams, apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('sitegroups')) {
                            return apiClient.get(rootResourceResponse.getLink('sitegroups'), { active: 'yes' }, 'long').then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    regionCollectionResponse: function($stateParams, apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('regions')) {
                            return apiClient.get(rootResourceResponse.getLink('regions'), null, 'long').then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    siteAssociateCollectionResponse: function($stateParams, apiClient, user) {
                        if ($stateParams.associateType) {
                            return apiClient.get(user.getLink('users'), {associateType: $stateParams.associateType}, null, 'long').then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    associateTypeCollectionResponse: function($stateParams, apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('siteassociatetypes')) {
                            return apiClient.get(rootResourceResponse.getLink('siteassociatetypes'), null, 'long').then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SITES" | translate }}'
                }
            });
    }

    function registerRoutesAdd($stateProvider) {
        $stateProvider
            .state('dashboard.admin.sites.list.add', {
                url: '/add',
                parent: 'dashboard.admin.sites.list',
                data: {
                    permissions: ['user_permission_manage_sites']
                },
                views: {
                    '@dashboard.admin.sites': {
                        templateUrl: '/modules/admin/sites/add-edit/site-form.html',
                        controller: 'SiteAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    siteResponse: function() {
                        return {};
                    },
                    siteGroupsCollectionResponse: function(rootResourceResponse) {
                        return rootResourceResponse.getResource('sitegroups');
                    },
                    regionsCollectionResponse: function(rootResourceResponse) {
                        return rootResourceResponse.getResource('regions', {active: true});
                    },
                    sectorsCollectionResponse: function(rootResourceResponse) {
                        return rootResourceResponse.getResource('sectors', {active: true});
                    },
                    billingTypeCollectionResponse: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('billingtypes')) {
                            return apiClient.get(rootResourceResponse.getLink('billingtypes'), {order: 'default'}).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    siteTreatmentTypeCollectionResponse: function() {
                        return null;
                    },
                    treatmentTypeCollectionResponse: function(rootResourceResponse) {
                        return rootResourceResponse.getResource('treatmenttypes');
                    },
                    meridianConfigurationCollectionResponse: function(meridianConfigurationCollectionResponse) {
                        return meridianConfigurationCollectionResponse;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SITE_ADD" | translate }}'
                }
            });
    }

    function registerRoutesDetails($stateProvider) {
        $stateProvider
            .state('dashboard.admin.sites.list.details', {
                url: '/details/{resource}',
                abstract: true,
                parent: 'dashboard.admin.sites.list',
                data: {
                    permissions: ['user_permission_view_sites', 'user_permission_client_admin_manage_sites']
                },
                views: {
                    '@dashboard.admin.sites': {
                        templateUrl: '/modules/admin/sites/details/site-details.html',
                        controller: 'SiteDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    siteResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    isActive: function() {
                        return function(site) {
                            var currentDate = new Date().toRFC3339String();
                            return ((typeof site.activeAt !== 'undefined' && site.activeAt < currentDate) && (typeof site.inactiveAt === 'undefined' || site.inactiveAt > currentDate));
                        };
                    },
                    isEditable: function(userManager) {
                        return userManager.hasPermission('user_permission_manage_sites') || userManager.hasPermission('site_permission_edit') || userManager.hasPermission('user_permission_client_admin_manage_sites');
                    },
                    approversResponse: function(siteResponse) {
                        return siteResponse.getResource('approvers');
                    },
                    quoteThresholdsCollectionResponse: function (apiClient, siteResponse, lodash, userManager) {
                        if(
                            (
                                userManager.hasPermission('user_permission_manage_sites') ||
                                userManager.hasPermission('site_permission_view_approvers')
                            ) && siteResponse.getLink('thresholds')
                        ) {
                            return apiClient.get(siteResponse.getLink('thresholds')).then(function (response) {

                                if( response !== 'undefined') {
                                    var unlimitedThreshold = lodash.remove(response.quoteThresholds, function(threshold) { return typeof threshold.value === 'undefined' });
                                    response.quoteThresholds.unshift.apply(response.quoteThresholds, unlimitedThreshold);

                                    return response;
                                } else {
                                    apiClient.noResourceFound();
                                }
                            });
                        }

                        return {};
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().siteResponse.name }}'
                }
            })
            .state('dashboard.admin.sites.list.details.info', {
                url: '/info',
                parent: 'dashboard.admin.sites.list.details',
                data: {
                    permissions: ['user_permission_view_sites', 'user_permission_client_admin_manage_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/admin/sites/details/info/site-info.html',
                        controller: 'SiteInfoController',
                        controllerAs: 'vm'
                    },
                    'feed@dashboard.admin.sites.list.details.info': {
                        templateUrl: '/modules/admin/sites/details/event/feed.html',
                        controller: 'SiteDetailsEventFeedController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    eventCollectionResponse: function (apiClient, siteResponse) {
                        if (siteResponse.getLink('events')) {
                            return apiClient.get(siteResponse.getLink('events'), { datagroup: 'details' }).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    meridianConfigurationCollectionResponse: function(meridianConfigurationCollectionResponse) {
                        return meridianConfigurationCollectionResponse;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ "INFO" | translate }} '
                }
            })
            .state('dashboard.admin.sites.list.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.admin.sites.list.details.info',
                data: {
                    permissions: ['user_permission_manage_sites', 'user_permission_client_admin_manage_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/admin/sites/add-edit/site-form.html',
                        controller: 'SiteAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    siteGroupsCollectionResponse: function(rootResourceResponse) {
                        return rootResourceResponse.getResource('sitegroups');
                    },
                    regionsCollectionResponse: function(rootResourceResponse) {
                        return rootResourceResponse.getResource('regions', {active: true});
                    },
                    sectorsCollectionResponse: function(rootResourceResponse) {
                        return rootResourceResponse.getResource('sectors', {active: true});
                    },
                    billingTypeCollectionResponse: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('billingtypes')) {
                            return apiClient.get(rootResourceResponse.getLink('billingtypes'), {order: 'default'}).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    siteTreatmentTypeCollectionResponse: function(siteResponse) {
                        return siteResponse.getResource('treatmenttypes');
                    },
                    treatmentTypeCollectionResponse: function(rootResourceResponse) {
                        return rootResourceResponse.getResource('treatmenttypes');
                    },
                    meridianConfigurationCollectionResponse: function (rootResourceResponse, siteResponse, apiClient) {
                        if (rootResourceResponse.getLink('meridian-configuration')) {
                            return apiClient.get(rootResourceResponse.getLink('meridian-configuration'), { type: 'systemLinks', site: siteResponse.id }).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesAssociates($stateProvider) {
        $stateProvider
            .state('dashboard.admin.sites.list.details.info.associates', {
                url: '/associates',
                parent: 'dashboard.admin.sites.list.details.info',
                data: {
                    permissions: ['user_permission_view_sites']
                },
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.admin.sites.list.details.info.associates.edit', {
                url: '/edit',
                parent: 'dashboard.admin.sites.list.details.info.associates',
                data: {
                    permissions: ['user_permission_manage_sites', 'user_permission_client_admin_manage_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/common/associates/associates-form.html',
                        controller: 'AssociatesEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    membershipsResponse: function (siteResponse, apiClient) {
                        return apiClient.get(siteResponse.getLink('memberships'), {
                            limit: 30,
                            permission: ['site_permission_can_be_associate'],
                            datagroup: 'list',
                            userActive: true
                        });
                    },
                    associatesResponse: function(apiClient, siteResponse, userManager) {
                        if(userManager.hasPermission('site_permission_view_associates')) {
                            return apiClient.get(siteResponse.getLink('associates')).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    associateTypesResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('siteassociatetypes')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    associatesCollection: function(associatesResponse, associateTypesResponse, siteAssociatesService) {
                        return siteAssociatesService.buildSiteAssociateObject(associatesResponse, associateTypesResponse);
                    }
                },
                scroll: false,
                ncyBreadcrumb: {
                    label: '{{ ::"SITE_ASSOCIATES" | translate }}'
                }
            });
    }

    function registerRoutesContacts($stateProvider) {
        $stateProvider
            .state('dashboard.admin.sites.list.details.contacts', {
                url: '/contacts?contactName&contactEmail&contactRole',
                parent: 'dashboard.admin.sites.list.details',
                data: {
                    permissions: ['user_permission_view_sites', 'user_permission_client_admin_manage_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/admin/sites/details/contacts/contact-lists.html',
                        controller: 'SiteContactsController',
                        controllerAs: 'vm'
                    },
                    'contacts@dashboard.admin.sites.list.details.contacts': {
                        templateUrl: '/modules/common/site-contacts/contacts-list.html',
                        controller: 'CommonSiteContactsController',
                        controllerAs: 'vm',
                    }
                },
                params: {
                    locationOrder: 'id',
                    contactName: null,
                    contactEmail: null,
                    contactCompany: null,
                    contactRole:null,
                    contactPage: '1',
                    contactLimit: '30',
                    contactOrder: 'name',
                },
                resolve: {
                    contactsResponse: function (apiClient, siteResponse, $stateParams) {
                        var params = angular.extend({}, $stateParams, {
                            name: $stateParams.contactName,
                            email: $stateParams.contactEmail,
                            company: $stateParams.contactCompany,
                            role: $stateParams.contactRole,
                            page: $stateParams.contactPage,
                            limit: $stateParams.contactLimit,
                            order: $stateParams.contactOrder,
                        });

                        return apiClient.get(siteResponse.getLink('contacts'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    contactInformationResponse: function(siteResponse) {
                        return siteResponse.getResource('contactinformation');
                    },
                    locationsCollectionResponse: function(siteResponse, $stateParams) {
                        return siteResponse.getResource('locations', angular.extend({}, $stateParams, {order: $stateParams.locationOrder}));
                    },
                    selectedSiteResponse: function(siteResponse) {
                        return siteResponse;
                    },
                    config: function() {
                        return {
                            contactType: 'contact',
                            detailRoute: '.details',
                            label: 'SITE_CONTACT',
                            tabTitle: 'CONTACTS',
                            noBorder: false
                        };
                    },
                    regionCollectionResponse: function() {
                        return null;
                    },
                    canEdit: function(userManager) {
                        return userManager.hasPermission('user_permission_manage_sites') || userManager.hasPermission('site_permission_edit') || userManager.hasPermission('user_permission_client_admin_manage_sites');
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"CONTACTS" | translate }}'
                }
            })
            .state('dashboard.admin.sites.list.details.contacts.add', {
                url: '/add',
                parent: 'dashboard.admin.sites.list.details.contacts',
                data: {
                    permissions: ['user_permission_manage_sites', 'user_permission_client_admin_manage_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/common/site-contacts/add-edit/contacts-addedit.html',
                        controller: 'CommonSiteContactsAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    contactResponse: function() {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.admin.sites.list.details.contacts.details', {
                url: '/details/{contactResource}',
                parent: 'dashboard.admin.sites.list.details.contacts',
                data: {
                    permissions: ['user_permission_view_sites', 'user_permission_client_admin_manage_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/common/site-contacts/details/contacts-details.html',
                        controller: 'CommonSiteContactsDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    contactResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.contactResource.decode()).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    contactInformationResponse: function(apiClient, contactResponse) {
                        var contactInformationLink = null;

                        if (contactResponse.user) {
                            contactInformationLink = contactResponse.user.getLink('contactinformation');
                        } else {
                            contactInformationLink = contactResponse.getLink('contactinformation');
                        }

                        if (contactInformationLink === null) {
                            return apiClient.noResourceFound();
                        }

                        return apiClient.get(contactInformationLink).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().contactResponse.name }}'
                }
            })
            .state('dashboard.admin.sites.list.details.contacts.details.edit', {
                url: '/edit',
                parent: 'dashboard.admin.sites.list.details.contacts.details',
                data: {
                    permissions: ['user_permission_manage_sites', 'user_permission_client_admin_manage_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/common/site-contacts/add-edit/contacts-addedit.html',
                        controller: 'CommonSiteContactsAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    config: function() {
                        return {
                            contactType: 'contact',
                            detailRoute: '.details',
                            label: 'SITE_CONTACT',
                            mode: 'edit'
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesLocations($stateProvider) {
        $stateProvider
            .state('dashboard.admin.sites.list.details.locations', {
                url: '/locations?locationPage&locationLimit&locationOrder&locationName',
                parent: 'dashboard.admin.sites.list.details',
                data: {
                    permissions: ['user_permission_view_sites', 'user_permission_client_admin_manage_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/common/site/details/locations/locations-list.html',
                        controller: 'CommonLocationsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    locationPage: '1',
                    locationLimit: '30',
                    locationOrder: 'name',
                    locationName: null
                },
                resolve: {
                    locationsCollectionResponse: function(apiClient, siteResponse, $stateParams, $state) {
                        return apiClient.get(siteResponse.getLink('locations'), angular.extend({}, $stateParams, {
                            page: $stateParams.locationPage,
                            limit: $stateParams.locationLimit,
                            order: $stateParams.locationOrder,
                            active: $stateParams.locationActive,
                            name: $stateParams.locationName
                        })).then(function(response) {
                            if (response && $stateParams.locationPage != 1 && $stateParams.locationPage > response.pages) {
                                $stateParams.locationPage = 1;
                                return $state.go('dashboard.admin.sites.list.details.locations', $stateParams, {reload: 'dashboard.admin.sites.list.details.locations.details'});
                            }

                            return response || apiClient.noResourceFound();
                        });
                    },
                    billingTypeCollectionResponse: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('billingtypes')) {
                            return apiClient.get(rootResourceResponse.getLink('billingtypes'), {order: 'default'}).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    config: function() {
                        return {
                            readOnly: false
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"LOCATIONS" | translate }}'
                }
            })
            .state('dashboard.admin.sites.list.details.locations.details', {
                url: '/details/{locationResource}',
                parent: 'dashboard.admin.sites.list.details.locations',
                data: {
                    permissions: ['user_permission_view_sites', 'user_permission_client_admin_manage_sites']
                },
                abstract: true,
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/common/site/details/locations/tabs.html',
                        controller: 'LocationTabsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    childLocationPage: '1',
                    childLocationLimit: '30',
                    childLocationOrder: 'name'
                },
                resolve: {
                    locationResponse: function (apiClient, $stateParams, $state) {
                        if ($stateParams.locationResource !== '') {
                            return apiClient.get($stateParams.locationResource.decode()).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        } else {
                            return {};
                        }
                    },
                    config: function(userManager) {
                        return {
                            readOnly: !userManager.hasPermission(['site_permission_edit_locations', 'user_permission_client_admin_edit_locations'])
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().locationResponse.name }} '
                }
            })
            .state('dashboard.admin.sites.list.details.locations.details.info', {
                url: '/info',
                parent: 'dashboard.admin.sites.list.details.locations.details',
                data: {
                    permissions: ['user_permission_view_sites', 'user_permission_client_admin_manage_sites']
                },
                views: {
                    'tab-panel@dashboard.admin.sites.list.details.locations.details': {
                        templateUrl: '/modules/common/site/details/locations/details/location-details.html',
                        controller: 'CommonLocationDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    locationsCollectionResponse: function (apiClient, locationResponse, $stateParams, $state) {
                        return apiClient.get(locationResponse.getLink('children'), angular.extend({}, $stateParams, {
                            page: $stateParams.childLocationPage,
                            limit: $stateParams.childLocationLimit,
                            order: $stateParams.locationOrder,
                            active: $stateParams.childLocationActive
                        })).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.admin.sites.list.details.locations.details.add', {
                url: '/details/{parentLocationResponse}/add',
                parent: 'dashboard.admin.sites.list.details.locations',
                data: {
                    permissions: ['user_permission_manage_sites', 'user_permission_client_admin_manage_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/admin/sites/details/locations/add-edit/location-form.html',
                        controller: 'LocationAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    locationResponse: function() {
                        return {};
                    },
                    parentLocationResponse: function(apiClient, $stateParams) {
                        if ($stateParams.parentLocationResponse !== '') {
                            return apiClient.get($stateParams.parentLocationResponse.decode()).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        } else {
                            return {};
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.admin.sites.list.details.locations.add', {
                url: '/add',
                parent: 'dashboard.admin.sites.list.details.locations.details',
                data: {
                    permissions: ['user_permission_manage_sites', 'user_permission_client_admin_manage_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/admin/sites/details/locations/add-edit/location-form.html',
                        controller: 'LocationAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    parentLocationResponse: function () {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"Add" | translate }}'
                }
            })
            .state('dashboard.admin.sites.list.details.locations.edit', {
                url: '/edit',
                parent: 'dashboard.admin.sites.list.details.locations.details',
                data: {
                    permissions: ['user_permission_manage_sites', 'user_permission_client_admin_manage_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/admin/sites/details/locations/add-edit/location-form.html',
                        controller: 'LocationAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    parentLocationResponse: function () {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.admin.sites.list.details.locations.details.notes', {
                url: '/notes',
                parent: 'dashboard.admin.sites.list.details.locations.details',
                data: {
                    permissions: ['user_permission_view_sites', 'user_permission_client_admin_manage_sites']
                },
                views: {
                    'tab-panel@dashboard.admin.sites.list.details.locations.details': {
                        templateUrl: '/modules/user/sites/details/notes/notes-list.html',
                        controller: 'UserNotesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    noteCollectionResponse: function(apiClient, locationResponse) {
                        return apiClient.get(locationResponse.getLink('notes'), {active: 'all'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteNoteTypeCollectionResponse: function (apiClient, rootResourceResponse) {
                        return rootResourceResponse.getResource('sitenotetypes');
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"LOCATION_NOTES" | translate }}'
                }
            })
            .state('dashboard.admin.sites.list.details.locations.details.notes.edit', {
                url: '/edit',
                parent: 'dashboard.admin.sites.list.details.locations.details.notes',
                data: {
                    permissions: ['user_permission_manage_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/admin/sites/details/notes/edit/notes-form.html',
                        controller: 'NotesEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesPartnerships($stateProvider) {
        $stateProvider
            .state('dashboard.admin.sites.list.details.partnerships', {
                url: '/partnerships?partnershipOrder&partnershipPage&partnershipLimit&contractorApproved&serviceProvidersStatus&serviceProvidersTelephone&serviceProvidersEmail&serviceProvidersAddress&serviceProviderGroups&partnerships&trainedAtStart&trainedAtEnd&activeAtStart&activeAtEnd&successShareSetup',
                parent: 'dashboard.admin.sites.list.details',
                data: {
                    permissions: ['user_permission_view_sites', 'user_permission_client_admin_manage_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/admin/sites/details/partnerships/partnerships-list.html',
                        controller: 'SitePartnershipsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    partnershipOrder: 'service',
                    partnershipActive: null,
                    includeInactiveServiceProviders: 'true',
                    includeTrainingServiceProviders: 'true',
                    partnershipPage: '1',
                    partnershipLimit: '30',
                    contractorApproved: null,
                    name: null,
                    serviceProvidersTelephone: null,
                    serviceProvidersEmail: null,
                    serviceProvidersAddress: null,
                    serviceProviderGroups: {array: true},
                    serviceProvidersStatus: null,
                    partnerships: {array: true},
                    trainedAtStart: null,
                    trainedAtEnd: null,
                    activeAtStart: null,
                    activeAtEnd: null,
                    items: null
                },
                resolve: {
                    partnershipsResponse: function(apiClient, siteResponse, $stateParams) {
                        return apiClient.get(
                            siteResponse.getLink('partnerships'),
                            angular.extend({}, $stateParams, {
                                page: $stateParams.partnershipPage,
                                limit: $stateParams.partnershipLimit,
                                order: $stateParams.partnershipOrder,
                                active: $stateParams.partnershipActive,
                                global: true,
                                'partnerships[]': $stateParams.partnerships,
                                'serviceProviderGroups[]': $stateParams.serviceProviderGroups
                            })
                        ).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"PARTNERSHIPS" | translate }}'
                }
            })
            .state('dashboard.admin.sites.list.details.partnerships.add', {
                url: '/add',
                parent: 'dashboard.admin.sites.list.details.partnerships',
                data: {
                    permissions: ['user_permission_manage_sites', 'user_permission_client_admin_manage_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/common/partnership/add/partnership-add.html',
                        controller: 'PartnershipAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    /**
                     * While unused, user is required as a dependency here to prevent race conditions against userManager.
                     * Do not remove it!
                     */
                    userResponse: function(user) {
                        return user;
                    },
                    sitePartnerships: function(apiClient, siteResponse, rootResourceResponse) {

                        return apiClient.get(
                            rootResourceResponse.getLink('serviceproviders'),
                            {
                                excludeSite: siteResponse.id
                            }
                        ).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.admin.sites.list.details.partnerships.details', {
                url: '/{partnershipResource}',
                parent: 'dashboard.admin.sites.list.details.partnerships',
                data: {
                    permissions: ['user_permission_view_sites', 'user_permission_client_admin_manage_sites']
                },
                abstract: true,
                resolve: {
                    partnershipResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.partnershipResource.decode());
                    },
                    serviceProviderResponse: function(apiClient, partnershipResponse) {
                        return apiClient.get(partnershipResponse.getLink('serviceprovider')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    priorityCreateType: function() {
                        return 'create-partnership-priority';
                    },
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/common/partnership/tabs.html',
                        controller: 'PartnershipTabsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().partnershipResponse._links.serviceprovider.title }}'
                }
            })
            .state('dashboard.admin.sites.list.details.partnerships.details.info', {
                url: '/info',
                parent: 'dashboard.admin.sites.list.details.partnerships.details',
                data: {
                    permissions: ['user_permission_view_sites', 'user_permission_client_admin_manage_sites']
                },
                views: {
                    'tab-panel@dashboard.admin.sites.list.details.partnerships.details': {
                        templateUrl: '/modules/common/partnership/info/partnership-info.html',
                        controller: 'PartnershipInfoController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.admin.sites.list.details.partnerships.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.admin.sites.list.details.partnerships.details.info',
                data: {
                    permissions: ['user_permission_manage_sites', 'user_permission_client_admin_manage_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/common/partnership/edit/partnership-edit.html',
                        controller: 'PartnershipEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.admin.sites.list.details.partnerships.details.priorities', {
                url: '/priorities?priorityPage&priorityLimit&priorityOrder&priorityName&priorityStatus',
                parent: 'dashboard.admin.sites.list.details.partnerships.details',
                data: {
                    permissions: ['user_permission_view_sites']
                },
                views: {
                    'tab-panel@dashboard.admin.sites.list.details.partnerships.details': {
                        templateUrl: '/modules/common/priorities/list/priorities-list.html',
                        controller: 'PrioritiesListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    prioritiesCollection: function(apiClient, partnershipResponse, $stateParams) {
                        return apiClient.get(partnershipResponse.getLink('priorities'), angular.extend({}, $stateParams, {
                            page: $stateParams.priorityPage,
                            limit: $stateParams.priorityLimit,
                            order: $stateParams.priorityOrder,
                            name: $stateParams.priorityName,
                            active: $stateParams.priorityStatus
                        }));
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"PRIORITIES" | translate }}'
                },
                params: {
                    priorityPage: '1',
                    priorityLimit: '30',
                    priorityOrder: 'name',
                    priorityName: null,
                    priorityStatus: null
                }
            })
            .state('dashboard.admin.sites.list.details.partnerships.details.priorities.add', {
                url: '/add',
                parent: 'dashboard.admin.sites.list.details.partnerships.details.priorities',
                data: {
                    permissions: ['user_permission_manage_sites']
                },
                views: {
                    'tab-panel@dashboard.admin.sites.list.details.partnerships.details': {
                        templateUrl: '/modules/common/priorities/addedit/priorities-addedit.html',
                        controller: 'PrioritiesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    priorityResponse: function() {
                        return {};
                    },
                    priorityCreateLink: function(prioritiesCollection) {
                        return prioritiesCollection.getLink('create-partnership-priority');
                    },
                    config: function() {
                        return {
                            isIncludeDashboardLimitReached: 'disabled',
                            noBorder: true
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.admin.sites.list.details.partnerships.details.priorities.details', {
                url: '/details/{priorityResource}',
                abstract: true,
                parent: 'dashboard.admin.sites.list.details.partnerships.details.priorities',
                data: {
                    permissions: ['user_permission_view_sites']
                },
                resolve: {
                    priorityResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.priorityResource.decode());
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().priorityResponse.name }}'
                }
            })
            .state('dashboard.admin.sites.list.details.partnerships.details.priorities.details.priorityinfo', {
                url: '/info',
                parent: 'dashboard.admin.sites.list.details.partnerships.details.priorities.details',
                data: {
                    permissions: ['user_permission_view_sites']
                },
                views: {
                    'tab-panel@dashboard.admin.sites.list.details.partnerships.details': {
                        templateUrl: '/modules/common/priorities/details/info/priorities-info.html',
                        controller: 'PrioritiesDetailsInfoController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    config: function () {
                        return {
                            isIncludeDashboardLimitReached: 'disabled',
                            noBorder: true
                        };
                    }
                },

                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }

            })
            .state('dashboard.admin.sites.list.details.partnerships.details.priorities.details.priorityinfo.edit', {
                url: '/edit',
                parent: 'dashboard.admin.sites.list.details.partnerships.details.priorities.details.priorityinfo',
                data: {
                    permissions: ['user_permission_manage_sites']
                },
                views: {
                    'tab-panel@dashboard.admin.sites.list.details.partnerships.details': {
                        templateUrl: '/modules/common/priorities/addedit/priorities-addedit.html',
                        controller: 'PrioritiesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    priorityCreateLink: function() {
                        return null;
                    },
                    config: function() {
                        return {
                            isIncludeDashboardLimitReached: 'disabled',
                            noBorder: true
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.admin.sites.list.details.partnerships.details.surveys', {
                url: '/surveys',
                parent: 'dashboard.admin.sites.list.details.partnerships.details',
                data: {
                    permissions: ['user_permission_view_sites']
                },
                resolve: {
                    surveysResponse: function(apiClient, partnershipResponse) {
                        return apiClient.get(partnershipResponse.getLink('surveys')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                views: {
                    'tab-panel@dashboard.admin.sites.list.details.partnerships.details': {
                        templateUrl: '/modules/common/partnership/surveys/list/partnership-surveys.html',
                        controller: 'PartnershipSurveysController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SURVEYS" | translate }}'
                }
            })
            .state('dashboard.admin.sites.list.details.partnerships.details.surveys.add', {
                url: '/add',
                parent: 'dashboard.admin.sites.list.details.partnerships.details.surveys',
                data: {
                    permissions: ['user_permission_manage_sites']
                },
                views: {
                    'tab-panel@dashboard.admin.sites.list.details.partnerships.details': {
                        templateUrl: '/modules/common/partnership/surveys/add/survey-add.html',
                        controller: 'PartnershipSurveyAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    surveysRootResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('surveys'), {limit: 200, status: 'active'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SURVEY_ADD" | translate }}'
                }
            })

            //Service Types
            .state('dashboard.admin.sites.list.details.partnerships.details.service-types', {
                url: '/service-types',
                parent: 'dashboard.admin.sites.list.details.partnerships.details',
                data: {
                    permissions: ['user_permission_view_sites']
                },
                views: {
                    'tab-panel@dashboard.admin.sites.list.details.partnerships.details': {
                        templateUrl: '/modules/common/service-types/list/service-types.html',
                        controller: 'CommonServiceTypesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    serviceTypesCollectionResponse: function(apiClient, partnershipResponse) {
                        return apiClient.get(partnershipResponse.getLink('servicetypes')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function() {
                        return {
                            noBorder: true
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SERVICE_TYPES" | translate }}'
                }
            })
            .state('dashboard.admin.sites.list.details.partnerships.details.service-types.add', {
                url: '/add',
                parent: 'dashboard.admin.sites.list.details.partnerships.details.service-types',
                data: {
                    permissions: ['user_permission_manage_sites']
                },
                views: {
                    'tab-panel@dashboard.admin.sites.list.details.partnerships.details': {
                        templateUrl: '/modules/common/service-types/add/service-type-add.html',
                        controller: 'CommonServiceTypeAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    serviceTypesRootResponse: function($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('servicetypes')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SERVICE_TYPE_ADD" | translate }}'
                }
            });
        }

        function registerRoutesWebhooks($stateProvider) {
            $stateProvider
            .state('dashboard.admin.sites.list.details.partnerships.details.webhooks', {
                url: '/webhooks?webhookPage&webhookLimit&webhookOrder&webhookName',
                parent: 'dashboard.admin.sites.list.details.partnerships.details',
                data: {
                    permissions: ['user_permission_view_sites']
                },
                views: {
                    'tab-panel@dashboard.admin.sites.list.details.partnerships.details': {
                        templateUrl: '/modules/common/webhooks/list/webhooks-list.html',
                        controller: 'WebhooksListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    webhooksCollection: function(apiClient, partnershipResponse, $stateParams) {
                        return apiClient.get(partnershipResponse.getLink('webhooks'), angular.extend({}, $stateParams, {
                            page: $stateParams.webhookPage,
                            limit: $stateParams.webhookLimit,
                            order: $stateParams.webhookOrder,
                            name: $stateParams.webhookName
                        }));
                    },
                    webhookCreateType: function() {
                        return 'create-partnership-webhook';
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"WEBHOOKS" | translate }}'
                },
                params: {
                    webhookPage: '1',
                    webhookLimit: '30',
                    webhookOrder: '-id',
                    webhookName: null
                }
            })
            .state('dashboard.admin.sites.list.details.partnerships.details.webhooks.add', {
                url: '/add',
                parent: 'dashboard.admin.sites.list.details.partnerships.details.webhooks',
                data: {
                    permissions: ['user_permission_manage_sites']
                },
                views: {
                    'tab-panel@dashboard.admin.sites.list.details.partnerships.details': {
                        templateUrl: '/modules/common/webhooks/addedit/webhooks-addedit.html',
                        controller: 'WebhooksAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    webhookResponse: function() {
                        return {};
                    },
                    webhookCreateLink: function(webhooksCollection) {
                        return webhooksCollection.getLink('create-partnership-webhook');
                    },
                    config: function() {
                        return {
                            noBorder: true
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.admin.sites.list.details.partnerships.details.webhooks.details', {
                url: '/details/{webhookResource}',
                abstract: true,
                parent: 'dashboard.admin.sites.list.details.partnerships.details.webhooks',
                data: {
                    permissions: ['user_permission_view_sites']
                },
                resolve: {
                    webhookResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.webhookResource.decode());
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().webhookResponse.url }}'
                }
            })
            .state('dashboard.admin.sites.list.details.partnerships.details.webhooks.details.webhooksinfo', {
                url: '/info',
                parent: 'dashboard.admin.sites.list.details.partnerships.details.webhooks.details',
                data: {
                    permissions: ['user_permission_view_sites']
                },
                views: {
                    'tab-panel@dashboard.admin.sites.list.details.partnerships.details': {
                        templateUrl: '/modules/common/webhooks/details/info/webhooks-info.html',
                        controller: 'WebhooksDetailsInfoController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    serviceProviderResponse: function() {
                        return {};
                    },
                    config: function() {
                        return {
                            noBorder: true
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }

            })
            .state('dashboard.admin.sites.list.details.partnerships.details.webhooks.details.webhooksinfo.edit', {
                url: '/edit',
                parent: 'dashboard.admin.sites.list.details.partnerships.details.webhooks.details.webhooksinfo',
                data: {
                    permissions: ['user_permission_manage_sites']
                },
                views: {
                    'tab-panel@dashboard.admin.sites.list.details.partnerships.details': {
                        templateUrl: '/modules/common/webhooks/addedit/webhooks-addedit.html',
                        controller: 'WebhooksAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    webhookCreateLink: function(webhooksCollection) {
                        return null;
                    },
                    config: function() {
                        return {
                            noBorder: true
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.admin.sites.list.details.webhooks', {
                url: '/webhooks?webhookPage&webhookLimit&webhookOrder&webhookName',
                parent: 'dashboard.admin.sites.list.details',
                data: {
                    permissions: ['user_permission_view_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/common/webhooks/list/webhooks-list.html',
                        controller: 'WebhooksListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    webhooksCollection: function(apiClient, siteResponse, $stateParams) {
                        return apiClient.get(siteResponse.getLink('webhooks'), angular.extend({}, $stateParams, {
                            page: $stateParams.webhookPage,
                            limit: $stateParams.webhookLimit,
                            order: $stateParams.webhookOrder,
                            name: $stateParams.webhookName
                        }));
                    },
                    webhookCreateType: function() {
                        return 'create-site-webhook';
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"WEBHOOKS" | translate }}'
                },
                params: {
                    webhookPage: '1',
                    webhookLimit: '30',
                    webhookOrder: '-id',
                    webhookName: null
                }
            })
            .state('dashboard.admin.sites.list.details.webhooks.add', {
                url: '/add',
                parent: 'dashboard.admin.sites.list.details.webhooks',
                data: {
                    permissions: ['user_permission_manage_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/common/webhooks/addedit/webhooks-addedit.html',
                        controller: 'WebhooksAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    webhookResponse: function() {
                        return {};
                    },
                    webhookCreateLink: function(webhooksCollection) {
                        return webhooksCollection.getLink('create-site-webhook');
                    },
                    config: function() {
                        return {
                            noBorder: false
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.admin.sites.list.details.webhooks.details', {
                url: '/details/{webhookResource}',
                abstract: true,
                parent: 'dashboard.admin.sites.list.details.webhooks',
                data: {
                    permissions: ['user_permission_view_sites']
                },
                resolve: {
                    webhookResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.webhookResource.decode());
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().webhookResponse.url }}'
                }
            })
            .state('dashboard.admin.sites.list.details.webhooks.details.webhooksinfo', {
                url: '/info',
                parent: 'dashboard.admin.sites.list.details.webhooks.details',
                data: {
                    permissions: ['user_permission_view_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/common/webhooks/details/info/webhooks-info.html',
                        controller: 'WebhooksDetailsInfoController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    serviceProviderResponse: function() {
                        return {};
                    },
                    config: function () {
                        return {
                            noBorder: false
                        };
                    }
                },

                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }

            })
            .state('dashboard.admin.sites.list.details.webhooks.details.webhooksinfo.edit', {
                url: '/edit',
                parent: 'dashboard.admin.sites.list.details.webhooks.details.webhooksinfo',
                data: {
                    permissions: ['user_permission_manage_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/common/webhooks/addedit/webhooks-addedit.html',
                        controller: 'WebhooksAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    webhookCreateLink: function(webhooksCollection) {
                        return null;
                    },
                    config: function() {
                        return {
                            noBorder: false
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
        ;
    }

    function registerRoutesPriorities($stateProvider) {
        $stateProvider
            .state('dashboard.admin.sites.list.details.priorities', {
                url: '/priorities?priorityPage&priorityLimit&priorityOrder&priorityName&priorityStatus',
                parent: 'dashboard.admin.sites.list.details',
                data: {
                    permissions: ['user_permission_view_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/common/priorities/list/priorities-list.html',
                        controller: 'PrioritiesListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    prioritiesCollection: function(apiClient, siteResponse, $stateParams) {
                        return apiClient.get(siteResponse.getLink('priorities'), angular.extend({}, $stateParams, {
                            page: $stateParams.priorityPage,
                            limit: $stateParams.priorityLimit,
                            order: $stateParams.priorityOrder,
                            name: $stateParams.priorityName,
                            active: $stateParams.priorityStatus
                        }));
                    },
                    priorityCreateType: function() {
                        return 'create-site-priority';
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"PRIORITIES" | translate }}'
                },
                params: {
                    priorityPage: '1',
                    priorityLimit: '30',
                    priorityOrder: 'name',
                    priorityName: null,
                    priorityStatus: null
                }
            })
            .state('dashboard.admin.sites.list.details.priorities.add', {
                url: '/add',
                parent: 'dashboard.admin.sites.list.details.priorities',
                data: {
                    permissions: ['user_permission_manage_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/common/priorities/addedit/priorities-addedit.html',
                        controller: 'PrioritiesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    priorityResponse: function() {
                        return {};
                    },
                    priorityCreateLink: function(prioritiesCollection) {
                        return prioritiesCollection.getLink('create-site-priority');
                    },
                    config: function() {
                        return {
                            isIncludeDashboardLimitReached: 'disabled',
                            noBorder: false
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.admin.sites.list.details.priorities.details', {
                url: '/details/{priorityResource}',
                abstract: true,
                parent: 'dashboard.admin.sites.list.details.priorities',
                data: {
                    permissions: ['user_permission_view_sites']
                },
                resolve: {
                    priorityResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.priorityResource.decode());
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().priorityResponse.name }}'
                }
            })
            .state('dashboard.admin.sites.list.details.priorities.details.priorityinfo', {
                url: '/info',
                parent: 'dashboard.admin.sites.list.details.priorities.details',
                data: {
                    permissions: ['user_permission_view_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/common/priorities/details/info/priorities-info.html',
                        controller: 'PrioritiesDetailsInfoController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    config: function () {
                        return {
                            isIncludeDashboardLimitReached: 'disabled',
                            noBorder: false
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.admin.sites.list.details.priorities.details.priorityinfo.edit', {
                url: '/edit',
                parent: 'dashboard.admin.sites.list.details.priorities.details.priorityinfo',
                data: {
                    permissions: ['user_permission_manage_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/common/priorities/addedit/priorities-addedit.html',
                        controller: 'PrioritiesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    priorityCreateLink: function() {
                        return null;
                    },
                    config: function() {
                        return {
                            isIncludeDashboardLimitReached: 'disabled',
                            noBorder: false
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesSpillageType($stateProvider) {
        $stateProvider
            .state('dashboard.admin.sites.list.details.spillage-types', {
                url: '/spillage-types',
                parent: 'dashboard.admin.sites.list.details',
                data: {
                    permissions: ['user_permission_view_sites']
                },
                resolve: {
                    spillageTypeCollectionResponse: function (apiClient, siteResponse) {
                        if (siteResponse.getLink('spillagetypes')) {
                            return apiClient.getPage(siteResponse.getLink('spillagetypes'), { page: 1, limit: 999999 }).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                        return null;
                    }
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/common/spillage-types/spillage-types.html',
                        controller: 'SpillageTypesController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SPILLAGE_TYPES" | translate }}'
                }
            })
            .state('dashboard.admin.sites.list.details.spillage-types.add', {
                url: '/add/{parentResource}',
                parent: 'dashboard.admin.sites.list.details.spillage-types',
                data: {
                    permissions: ['user_permission_manage_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/common/spillage-types/add-edit/spillage-type-form.html',
                        controller: 'SpillageTypeAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    parentResponse: function (apiClient, $stateParams) {
                        if ($stateParams.parentResource) {
                            return apiClient.get($stateParams.parentResource.decode()).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }

                        return null;
                    },
                    spillageTypeResponse: function () {
                        return {};
                    },
                    spillageTypeCreateLink: function(spillageTypeCollectionResponse, siteResponse) {
                        return siteResponse.getLink('spillagetypes');
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.admin.sites.list.details.spillage-types.edit', {
                url: '/edit/{spillageTypeResource}',
                parent: 'dashboard.admin.sites.list.details.spillage-types',
                data: {
                    permissions: ['user_permission_manage_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/common/spillage-types/add-edit/spillage-type-form.html',
                        controller: 'SpillageTypeAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    spillageTypeCollectionResponse: function (apiClient, siteResponse, $stateParams) {
                        var resource = $stateParams.spillageTypeResource.decode();
                        var parts = resource.split('/');
                        var exclude = parts.pop();
                        if (siteResponse.getLink('spillagetypes')) {
                            return apiClient.getPage(siteResponse.getLink('spillagetypes'), { page: 1, limit: 999999, active: 1, exclude: exclude}).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                        return null;
                    },
                    spillageTypeResponse: function (apiClient, base64, $stateParams) {
                        return apiClient.get($stateParams.spillageTypeResource.decode()).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    parentResponse: function (apiClient, spillageTypeResponse) {
                        if (spillageTypeResponse.getLink('parent')) {
                            return apiClient.get(spillageTypeResponse.getLink('parent')).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }

                        return null;
                    },
                    spillageTypeCreateLink: function(spillageTypeCollectionResponse) {
                        return spillageTypeCollectionResponse.getLink('create');
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesWasteTreatmentTypes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.sites.list.details.waste-treatment-types', {
                url: '/waste-treatment-types?wtPage&wtLimit&wtOrder&wtId&wtName&wtStatus',
                parent: 'dashboard.admin.sites.list.details',
                data: {
                    permissions: ['user_permission_view_sites']
                },
                params: {
                    wtPage: '1',
                    wtLimit: '30',
                    wtOrder: 'name',
                    wtId: null,
                    wtName: '',
                    wtStatus: null
                },
                resolve: {
                    siteTreatmentTypeCollectionResponse: function (apiClient, $stateParams, siteResponse) {
                        if (siteResponse.getLink('treatmenttypes')) {
                            var params = {
                                page: $stateParams.wtPage,
                                limit: $stateParams.wtLimit,
                                order: $stateParams.wtOrder,
                                id: $stateParams.wtId,
                                name: $stateParams.wtName,
                                status: $stateParams.wtStatus
                            };

                            return apiClient.getPage(siteResponse.getLink('treatmenttypes'), params).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }

                        return null;
                    }
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/common/waste-treatment-types/waste-treatment-types.html',
                        controller: 'WasteTreatmentTypesController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"WASTE_TREATMENT_TARGETS" | translate }}'
                }
            })
            .state('dashboard.admin.sites.list.details.waste-treatment-types.add', {
                url: '/add',
                parent: 'dashboard.admin.sites.list.details.waste-treatment-types',
                data: {
                    permissions: ['user_permission_manage_sites', 'user_permission_manage_waste_management']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/common/waste-treatment-types/add-edit/waste-treatment-types-add-edit.html',
                        controller: 'WasteTreatmentTypeAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    treatmentTypeCollectionResponse: function(apiClient, siteResponse, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('treatmenttypes'), {status: true, excludeSite: siteResponse.id}).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    treatmentTypeResponse: function () {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.admin.sites.list.details.waste-treatment-types.edit', {
                url: '/edit/{treatmentTypeResource}',
                parent: 'dashboard.admin.sites.list.details.waste-treatment-types',
                data: {
                    permissions: ['user_permission_manage_sites', 'user_permission_manage_waste_management']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/common/waste-treatment-types/add-edit/waste-treatment-types-add-edit.html',
                        controller: 'WasteTreatmentTypeAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    treatmentTypeCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('treatmenttypes'), {status: true}).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    treatmentTypeResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.treatmentTypeResource.decode()).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesNotes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.sites.list.details.notes', {
                url: '/notes',
                parent: 'dashboard.admin.sites.list.details',
                data: {
                    permissions: ['user_permission_view_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/admin/sites/details/notes/notes-list.html',
                        controller: 'NotesController',
                        controllerAs: 'vm'
                    },
                    'feed@dashboard.admin.sites.list.details.notes': {
                        templateUrl: '/modules/common/event/event-feed/feed.html',
                        controller: 'FeedController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    noteCollectionResponse: function(apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('notes'), {active: 'all'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteNoteTypesCollectionResponse: function (apiClient, rootResourceResponse) {
                        return rootResourceResponse.getResource('sitenotetypes');
                    },
                    locationResponse: function() {
                        return {};
                    },
                    eventsCollection: function (apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('note-events')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    entityResponse: function (siteResponse) {
                        return siteResponse;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SITE_NOTES" | translate }}'
                }
            })
            .state('dashboard.admin.sites.list.details.notes.edit', {
                url: '/edit',
                parent: 'dashboard.admin.sites.list.details.notes',
                data: {
                    permissions: ['user_permission_manage_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/admin/sites/details/notes/edit/notes-form.html',
                        controller: 'NotesEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                },
            });
    }

    function registerRoutesThresholds($stateProvider) {
        $stateProvider
            .state('dashboard.admin.sites.list.details.thresholds', {
                url: '/thresholds',
                parent: 'dashboard.admin.sites.list.details',
                data: {
                    permissions: ['user_permission_view_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/admin/sites/details/thresholds/threshold-list.html',
                        controller: 'QuoteThresholdsListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    quoteThresholdsCollectionResponse: function (apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('thresholds')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"QUOTE_THRESHOLDS" | translate }}'
                }
            })
            .state('dashboard.admin.sites.list.details.thresholds.add', {
                url: '/add',
                parent: 'dashboard.admin.sites.list.details.thresholds',
                data: {
                    permissions: ['user_permission_manage_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/admin/sites/details/thresholds/add-edit/threshold-form.html',
                        controller: 'QuoteThresholdAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    quoteThresholdResponse: function () {
                        return {}
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.admin.sites.list.details.thresholds.details', {
                url: '/{thresholdResource}',
                parent: 'dashboard.admin.sites.list.details.thresholds',
                data: {
                    permissions: ['user_permission_view_sites']
                },
                abstract: true,
                views: parseBook({
                    '@dashboard.admin.sites.list.details': {
                        controller: 'QuoteThresholdDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    quoteThresholdResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.thresholdResource.decode()).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: "{{ ::(($resolves().quoteThresholdResponse.value || $resolves().quoteThresholdResponse.value == 0 ) ? ($resolves().quoteThresholdResponse.value | number:0) : ('UNLIMITED' | translate)) }}"
                }
            })
            .state('dashboard.admin.sites.list.details.thresholds.details.info', {
                url: '/info',
                parent: 'dashboard.admin.sites.list.details.thresholds.details',
                data: {
                    permissions: ['user_permission_view_sites']
                },
                views: parseBook({
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/admin/sites/details/thresholds/details/info/threshold-info.html',
                        controller: 'QuoteThresholdInfoController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.admin.sites.list.details.thresholds.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.admin.sites.list.details.thresholds.details.info',
                data: {
                    permissions: ['user_permission_manage_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/admin/sites/details/thresholds/add-edit/threshold-form.html',
                        controller: 'QuoteThresholdAddEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }
    function registerRoutesKPISurveys($stateProvider) {
        $stateProvider
            .state('dashboard.admin.sites.list.details.kpi-surveys', {
                url: '/kpi-surveys',
                parent: 'dashboard.admin.sites.list.details',
                data: {
                    permissions: ['user_permission_manage_sites'] // BE cgetAction requires manage site permission.
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/common/kpi-surveys/list/kpi-surveys.html',
                        controller: 'KPISurveyListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    surveysResponse: function(apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('surveys')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ :: "SURVEY" | translate }}'
                }
            })
            .state('dashboard.admin.sites.list.details.kpi-surveys.add', {
                url: '/add',
                parent: 'dashboard.admin.sites.list.details.kpi-surveys',
                data: {
                    permissions: ['user_permission_manage_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/common/kpi-surveys/add/kpi-surveys.add.html',
                        controller: 'KPISurveyAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    surveysRootResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('surveys'), {limit: 200, status: 'active'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            });
    }

    function registerBillingSettings($stateProvider) {
        $stateProvider
            .state('dashboard.admin.sites.list.details.billing-settings', {
                url: '/billing-settings',
                parent: 'dashboard.admin.sites.list.details',
                data: {
                    permissions: ['user_permission_view_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/common/billing-settings/details/billing-settings-details.html',
                        controller: 'CommonBillingSettingsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    billingSettingsResponse: function(apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('billingsettings'), {}).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    relatedCoreClientBillingSettingsResponse: function(apiClient, billingSettingsResponse) {
                        if (billingSettingsResponse.getLink('relatedcoreclientbillingsettings')) {
                            return apiClient.get(billingSettingsResponse.getLink('relatedcoreclientbillingsettings'), {}).then( function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        } else {
                            return {};
                        }
                    },
                    approversResponse: function(billingSettingsResponse, lodash) {
                        if (billingSettingsResponse.billingsettings.length) {
                             var billingSettings = lodash.first(billingSettingsResponse.billingsettings);
                              return billingSettings.getResource('approvers');
                         }
                     },
                    config: function() {
                        return {
                            type: 'site'
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"BILLING_SETTINGS" | translate }}'
                }
            });
    }

    function registerBillingSettingsEdit($stateProvider) {
        $stateProvider
            .state('dashboard.admin.sites.list.details.billing-settings.edit', {
                url: '/edit/{resource}',
                parent: 'dashboard.admin.sites.list.details.billing-settings',
                data: {
                    permissions: ['user_permission_manage_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/common/billing-settings/details/add-edit/billing-settings-add-edit.html',
                        controller: 'CommonBillingSettingsAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    pricingSchedulesResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('pricing-schedules'), {}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
        });
    }

    function registerBillingSettingsAdd($stateProvider) {
        $stateProvider
            .state('dashboard.admin.sites.list.details.billing-settings.add', {
                url: '/add',
                parent: 'dashboard.admin.sites.list.details.billing-settings',
                data: {
                    permissions: ['user_permission_manage_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/common/billing-settings/details/add-edit/billing-settings-add-edit.html',
                        controller: 'CommonBillingSettingsAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    pricingSchedulesResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('pricing-schedules'), {}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
        });
    }

    function registerRoutesApprovers($stateProvider) {
        $stateProvider
            .state('dashboard.admin.sites.list.details.info.approvers', {
                url: '/approvers',
                parent: 'dashboard.admin.sites.list.details.info',
                data: {
                    permissions: ['user_permission_view_sites']
                },
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.admin.sites.list.details.info.approvers.edit', {
                url: '/edit',
                parent: 'dashboard.admin.sites.list.details.info.approvers',
                data: {
                    permissions: ['user_permission_manage_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/common/approvers/approvers-form.html',
                        controller: 'ApproversEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    membershipsResponse: function (siteResponse, apiClient) {
                        return apiClient.get(siteResponse.getLink('memberships'), {
                            limit: 30,
                            permission: ['site_permission_can_be_approver'],
                            datagroup: 'list',
                            userActive: null
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"APPROVERS" | translate }}'
                }
            })
            .state('dashboard.admin.sites.list.details.billing-settings.approvers', {
                url: '/approvers',
                parent: 'dashboard.admin.sites.list.details.billing-settings',
                data: {
                    permissions: ['user_permission_view_sites']
                },
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.admin.sites.list.details.billing-settings.approvers.edit', {
                url: '/edit',
                parent: 'dashboard.admin.sites.list.details.billing-settings.approvers',
                data: {
                    permissions: ['user_permission_manage_sites']
                },
                views: {
                    '@dashboard.admin.sites.list.details': {
                        templateUrl: '/modules/common/billing-settings/details/edit-approvers/approvers-form.html',
                        controller: 'CommonBillingSettingsApproversEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    userCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('users'), { status: 'active', limit: 30 });
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"APPROVERS" | translate }}'
                }
            });
    }
})();
